import React from "react"
import "./social.scss";
import tracker from '../../../../utils/tracker';
import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton
} from 'react-share';
import { getPlainText } from '../../../../utils';
import { slice } from 'lodash';

const SocialButtons = (props) => {
    const { data, handleSetOpenCls, title, link, subjectTitle, description } = props;

    const words = getPlainText(description).split(" ");
    const desc = slice(words, 0, 20);
    
    return (
        <div className={data == true ? "w3-show" : "w3-hide"} style={{position: "relative"}}>
            <div> 
                <div onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    handleSetOpenCls(false);
                }} className="pw-ssb-icon pw-ssb-close" /> 
            </div>
            <div className="ssb-whatsapp"> 
                <WhatsappShareButton
                    beforeOnClick={() => tracker.trackSocial('Whatsapp', window.location.href)}
                    url={link}
                    title={`${'*'}${title}${'*'}${'\n'}${words.length > 20 ? `${desc.join(' ')}...${'\n'}` : `${desc.join(' ')}${'\n'}`}`}
                >
                    <div className="pw-ssb-icon pw-ssb-whatsapp" /> 
                </WhatsappShareButton>
            </div>
            <div className="ssb-facebook"> 
                <FacebookShareButton
                    beforeOnClick={() => tracker.trackSocial('Facebook', window.location.href)}
                    url={link}
                    quote={title}
                >
                    <div className="pw-ssb-icon pw-ssb-facebook"  /> 
                </FacebookShareButton> 
            </div>
            <div className="ssb-mail"> 
                <EmailShareButton
                    beforeOnClick={() => tracker.trackSocial('Email', window.location.href)}
                    url={link}
                    subject={`${subjectTitle}${' '}${' '}${title}`}
                    body={words.length > 20 ? `${desc.join(' ')}...${'\n'}` : `${desc.join(' ')}${'\n'}`}
                >
                    <div className="pw-ssb-icon pw-ssb-mail" /> 
                </EmailShareButton>
            </div>
        </div>
    );
}   

export default SocialButtons;
                        